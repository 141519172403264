import { action, observable } from 'mobx';
import sortBy from 'lodash/sortBy';
import orderController from './controller';
export const referenceStore = observable({
    ref56: [],
    refs: [],
    regions: [],
    geoPlaces: [],
    geoPlacesRequest: null,
    geoPlaceDirect: null,
    geoPlaceDirectRequest: null,
    error: null,
    route: null,
});
export const referenceActions = {
    getRef: async (refId) => {
        var _a, _b, _c, _d;
        const sortFunc = (arr) => {
            return sortBy(arr, (e) => {
                var _a;
                return (_a = e.sort) !== null && _a !== void 0 ? _a : 0;
            });
        };
        const refs = (_a = referenceStore.refs) !== null && _a !== void 0 ? _a : [];
        const ref = (_b = refs.find(v => v.refId === refId)) === null || _b === void 0 ? void 0 : _b.ref;
        if (ref) {
            return ref;
        }
        const response = await orderController.getUniversalReference(refId);
        referenceActions.handleError(response);
        if (!response.errorData) {
            referenceStore.refs = [...referenceStore.refs, { ref: sortFunc((_c = response.data) !== null && _c !== void 0 ? _c : []), refId }].sort();
        }
        return sortFunc((_d = response.data) !== null && _d !== void 0 ? _d : []);
    },
    getRegions: async () => {
        const response = await orderController.getRegions();
        referenceActions.handleError(response);
        if (!response.errorData) {
            const x = response.data.items;
            x[x.findIndex(v => v.path === 'msk')].path = '';
            referenceStore.regions = response.data.items;
        }
        /* referenceStore.regions = [
          {
            'fiasId': '26a25efa-4f63-4d4b-8db6-dfc29cdaab24',
            'region': 'Москва',
            'lat': 55.755864,
            'lng': 37.617698,
          },
          {
            'fiasId': 'c2deb16a-0330-4f05-821f-1d09c93331e6',
            'region': 'Санкт-Петербург',
            'lat': 59.938955,
            'lng': 30.315644,
          },
        ]; */
        return referenceStore.regions;
    },
    getGeoPlaces: async (request) => {
        var _a, _b, _c, _d;
        referenceStore.geoPlacesRequest = request;
        if (request.input) {
            const response = await orderController.getGeoPlaces(request);
            referenceActions.handleError(response);
            if (!response.errorData) {
                referenceStore.geoPlaces = (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.results) !== null && _b !== void 0 ? _b : [];
                return (_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.results) !== null && _d !== void 0 ? _d : [];
            }
            else {
                return [];
            }
        }
        return [];
    },
    getGeoPlaceDirect: async (request) => {
        var _a, _b;
        referenceStore.geoPlaceDirectRequest = request;
        const response = await orderController.getGeoPlaceDirect(request);
        referenceActions.handleError(response);
        if (!response.errorData) {
            referenceStore.geoPlaceDirect = (_a = response.data) !== null && _a !== void 0 ? _a : [];
        }
        return (_b = response.data) !== null && _b !== void 0 ? _b : {};
    },
    // TODO - вместо any можно попробовать типизировать ответ Яндекса, либо поискать готовый в типах
    getGeoPlaceReverse: async (request) => {
        var _a, _b, _c, _d;
        // referenceStore.geoPlaceDirectRequest = request;
        const response = await orderController.getGeoPlaceReverse(request);
        /* referenceActions.handleError(response);
        if (!response.errorData) {
          referenceStore.geoPlaceDirect = response.data ?? [];
        } */
        return (_d = (_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.GeoObjectCollection) === null || _c === void 0 ? void 0 : _c.featureMember[0]) !== null && _d !== void 0 ? _d : {};
    },
    handleError: action((response) => {
        if (response.errorData) {
            const { errorData } = response;
            referenceStore.error = errorData;
            throw errorData;
        }
    }),
    clearUR: action(() => {
        referenceStore.ref56 = [];
    }),
    clearError: action(() => {
        referenceStore.error = null;
    }),
    getRoute: async (request) => {
        // referenceStore.geoPlacesRequest = request;
        if (request) {
            const response = await orderController.getRoute(request);
            // referenceActions.handleError(response);
            referenceStore.route = response;
            //      if (!response.errorData) {
            // const routeReceived = response.data ?? null;
            // const routePrepared = routeReceived ? routeReceived[0].toRoute() : null;
            // Check if a route has coordinates
            // if (route.geometry.coordinates.length == 0) return;
            //     return response.data?.results ?? [];
        }
        else {
            return [];
        }
    },
    clearRoute: action(() => {
        referenceStore.route = null;
    }),
};
