import { action, observable, runInAction } from 'mobx';
import orderController from './controller';
export const authStore = observable({
    sendSmsRequest: null,
    confirmSmsRequest: null,
    confirmSmsResponse: null,
    error: null,
});
export const authActions = {
    sendSms: async (request) => {
        runInAction(() => {
            authStore.sendSmsRequest = request;
        });
        const response = await orderController.sendSms(request);
        if (response.errorData) {
            authStore.sendSmsRequest = null;
        }
        authActions.handleError(response);
        return response.data;
    },
    confirmSms: async (request) => {
        runInAction(() => {
            authStore.confirmSmsRequest = request;
        });
        const response = await orderController.confirmSms(request);
        if (response.errorData) {
            authStore.confirmSmsRequest = null;
            return response.errorData;
        }
        authActions.handleError(response);
        if (!response.errorData) {
            authStore.confirmSmsResponse = response.data;
        }
        return response.data;
    },
    handleError: action((response) => {
        if (response.errorData) {
            const { errorData } = response;
            authStore.error = errorData;
            throw errorData;
        }
    }),
    clearError: action(() => {
        authStore.error = null;
    }),
};
