import { referenceActions } from '../order/store/referenceStore';
export const fetchAddressByCoordinates = async (coordinates, callback) => {
    var _a, _b, _c;
    try {
        const response = await referenceActions.getGeoPlaceReverse(coordinates);
        const adr = (_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.GeoObject) === null || _a === void 0 ? void 0 : _a.metaDataProperty) === null || _b === void 0 ? void 0 : _b.GeocoderMetaData) === null || _c === void 0 ? void 0 : _c.text;
        callback(coordinates, adr, response === null || response === void 0 ? void 0 : response.placeId);
    }
    catch (e) {
        console.error(e);
        callback(coordinates);
    }
};
