import { action, observable } from 'mobx';
import orderController from './controller';
export const clientStore = observable({
    clientProfile: null,
    error: null,
});
export const clientActions = {
    getClient: async (token = null) => {
        var _a, _b;
        const response = await orderController.getClient(token);
        clientActions.handleError(response);
        if (!response.errorData) {
            clientStore.clientProfile = (_a = response.data) !== null && _a !== void 0 ? _a : [];
        }
        return (_b = response.data) !== null && _b !== void 0 ? _b : [];
    },
    updateClient: async (request, token = null) => {
        const response = await orderController.updateClient(request, token);
        clientActions.handleError(response);
        return response.data;
    },
    handleError: action((response) => {
        if (response.errorData) {
            const { errorData } = response;
            clientStore.error = errorData;
            throw errorData;
        }
    }),
    clearError: action(() => {
        clientStore.error = null;
    }),
};
