import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useMediaQuery } from '@react-hooks-library/core';
import { Logo, Avtoapp, RegionArrowIcon, MenuIcon, 
// BigPhone,
CloseIcon, MenuArrowUp, MenuArrowDown, } from '../core';
const MenuPanel = (props) => {
    var _a;
    const { referenceStore } = props;
    const { regions } = referenceStore;
    const isMobile = useMediaQuery('(max-width: 768px)');
    const [showMenu, setShowMenu] = useState(false);
    const [showNavigateMenu, setNavigateShowMenu] = useState(false);
    const [showExtendedMenu, setShowExtendedMenu] = useState(false);
    const navigate = useNavigate();
    const hostname = `https://${window.location.hostname}`;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: 'menu-box', children: _jsx(Container, { fluid: true, children: _jsxs("div", { className: 'menu-layer', children: [_jsx("div", { className: 'd-flex align-items-center', children: _jsx("div", { className: 'logo', "aria-hidden": 'true', children: _jsxs("a", { href: 'https://evacuator.avtoapp.ru/', children: [_jsx(Logo, {}), " "] }) }) }), _jsx("div", { className: 'd-flex align-items-center mobile-hidden', children: _jsx("div", { className: 'avtoapp', children: _jsxs("a", { href: 'https://evacuator.avtoapp.ru/', children: [_jsx(Avtoapp, { color: '#201D25' }), " "] }) }) }), _jsx("div", { className: 'd-flex align-items-center justify-content-start header-mobile-actions', children: _jsxs("div", { onClick: () => {
                                        setShowMenu(!showMenu);
                                    }, "aria-hidden": 'true', children: [_jsx(RegionArrowIcon, {}), (_a = props === null || props === void 0 ? void 0 : props.children) !== null && _a !== void 0 ? _a : 'Москва', showMenu && (_jsx(Dropdown, { onToggle: () => setShowMenu(false), children: _jsx(Dropdown.Menu, { show: showMenu, align: { sm: 'start', md: 'start' }, children: regions.map((v) => {
                                                    return (_jsx(Dropdown.Item, { onClick: () => navigate(v.path), children: v.name }, v.id));
                                                }) }) }))] }) }), isMobile && (_jsx("div", { className: 'd-flex align-items-center justify-content-end', children: showNavigateMenu ? (_jsx(CloseIcon, { onClick: () => {
                                        setNavigateShowMenu(!showNavigateMenu);
                                    } })) : (_jsx(MenuIcon, { onClick: () => {
                                        setNavigateShowMenu(!showNavigateMenu);
                                    } })) }))] }) }) }), isMobile && showNavigateMenu && (_jsxs("div", { className: 'second-menu-box', children: [_jsx("div", { className: 'menu', "aria-hidden": 'true', onClick: () => {
                            window.location.href = `${hostname}/about`;
                        }, children: "\u041E \u043D\u0430\u0441" }), _jsx("div", { className: 'menu', "aria-hidden": 'true', onClick: () => {
                            window.location.href = `${hostname}/app`;
                        }, children: "\u041F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u0435" }), _jsx("div", { className: 'menu', "aria-hidden": 'true', onClick: () => {
                            window.location.href = `${hostname}/contacts`;
                        }, children: "\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u044B" }), _jsx("div", { className: 'menu', "aria-hidden": 'true', onClick: () => {
                            setShowExtendedMenu(!showExtendedMenu);
                        }, children: _jsxs("div", { className: 'second-menu-services', children: [_jsx("div", { className: 'l1', children: "\u0423\u0441\u043B\u0443\u0433\u0438" }), _jsx("div", { className: 'l2', children: showExtendedMenu ? _jsx(MenuArrowUp, {}) : _jsx(MenuArrowDown, {}) })] }) }), showExtendedMenu
                        && (_jsxs(_Fragment, { children: [_jsx("div", { className: 'menu-extended', "aria-hidden": 'true', onClick: () => {
                                        window.location.href = `${hostname}/evakuator-manipulyator`;
                                    }, children: "\u042D\u0432\u0430\u043A\u0443\u0430\u0442\u043E\u0440 \u043C\u0430\u043D\u0438\u043F\u0443\u043B\u044F\u0442\u043E\u0440" }), _jsx("div", { className: 'menu-extended', "aria-hidden": 'true', onClick: () => {
                                        window.location.href = `${hostname}/evakuator-s-gidroplatformoj`;
                                    }, children: "\u042D\u0432\u0430\u043A\u0443\u0430\u0442\u043E\u0440 \u0441 \u0433\u0438\u0434\u0440\u0430\u0432\u043B\u0438\u0447\u0435\u0441\u043A\u043E\u0439 \u043F\u043B\u0430\u0442\u0444\u043E\u0440\u043C\u043E\u0439" }), _jsx("div", { className: 'menu-extended', "aria-hidden": 'true', onClick: () => {
                                        window.location.href = `${hostname}/evakuator-motociklov`;
                                    }, children: "\u042D\u0432\u0430\u043A\u0443\u0430\u0442\u043E\u0440 \u043C\u043E\u0442\u043E\u0446\u0438\u043A\u043B\u043E\u0432" }), _jsx("div", { className: 'menu-extended', "aria-hidden": 'true', onClick: () => {
                                        window.location.href = `${hostname}/evakuator-s-chastichnoj-pogruzkoj`;
                                    }, children: "\u042D\u0432\u0430\u043A\u0443\u0430\u0442\u043E\u0440 \u0441 \u0447\u0430\u0441\u0442\u0438\u0447\u043D\u043E\u0439 \u043F\u043E\u0433\u0440\u0443\u0437\u043A\u043E\u0439" }), _jsx("div", { className: 'menu-extended', "aria-hidden": 'true', onClick: () => {
                                        window.location.href = `${hostname}/evakuatsiya-spetstekhniki`;
                                    }, children: "\u042D\u0432\u0430\u043A\u0443\u0430\u0446\u0438\u044F \u0441\u043F\u0435\u0446\u0442\u0435\u0445\u043D\u0438\u043A\u0438" })] }))] }))] }));
};
export default inject('stateAppStore', 'referenceStore')(observer(MenuPanel));
