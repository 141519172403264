import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import Form from 'react-bootstrap/Form';
import { useFormContext, Controller } from 'react-hook-form';
// import InputGroup from 'react-bootstrap/InputGroup';
// import { useMediaQuery } from '@react-hooks-library/core';
import InputGroup from 'react-bootstrap/InputGroup';
import React from 'react';
import { SwitchOffIcon, SwitchOnIcon, } from '..';
import { iconSelector } from '../icons/iconSelector';
// import { HookFormResponse } from '../../order/api/order';
import { validateField } from './validation';
const VehicleNumberInput = (props, ref) => {
    const { label, required, controlId, name, iconType, validationSchema, shouldValidate, error, disabled, } = props;
    const { control, getValues, setValue } = useFormContext();
    const errorLocal = error !== null && error !== void 0 ? error : ((shouldValidate && validationSchema) ? validateField(name, getValues(), validationSchema) : null);
    const values = getValues();
    // const isMobile = useMediaQuery('(max-width: 768px)');
    const inputGroupComponent = (field) => {
        return (_jsx(InputGroup, { children: _jsx(Form.Control, Object.assign({}, field, { placeholder: label, 
                // inputMode='numeric'
                // value={ prepareCode(field.value) }
                value: field.value, onChange: (e) => {
                    // field.onChange(handleChangeCode(e));
                    field.onChange(e.target.value);
                }, className: `simple-edit sms_line_input ${errorLocal ? 'error-component error-border error-color-font' : 'border-none'}`, 
                // alwaysShowMask
                // type={ isMobile ? 'tel' : 'text' }
                disabled: disabled, ref: ref })) }));
    };
    const handleForeignClick = () => {
        setValue('isForeign', !(values === null || values === void 0 ? void 0 : values.isForeign));
    };
    return (_jsxs("div", { children: [_jsxs(Form.Group, { controlId: controlId, className: 'componentRow', children: [label && (_jsx(Form.Label, { className: `label mb-0${required ? ' required' : ''}${iconType ? ' margin-left-label' : ''}${errorLocal ? ' error-color-font' : ''}`, children: label })), _jsx(Controller, { name: name, control: control, render: ({ field }) => {
                            if (iconType) {
                                return (_jsxs("div", { className: 'inputDesign', children: [_jsx("div", { className: 'icon', children: iconSelector(iconType) }), inputGroupComponent(field)] }));
                            }
                            return inputGroupComponent(field);
                        } }), _jsx("div", { className: 'error-container', children: _jsx("div", { children: errorLocal !== null && errorLocal !== void 0 ? errorLocal : ' ' }) })] }), _jsxs("div", { className: 'foreign-div', children: [_jsx("div", { children: "\u0418\u043D\u043E\u0441\u0442\u0440\u0430\u043D\u043D\u044B\u0439 \u043D\u043E\u043C\u0435\u0440" }), _jsx("div", { onClick: handleForeignClick, children: values.isForeign ? _jsx(SwitchOffIcon, {}) : _jsx(SwitchOnIcon, {}) }), _jsx("div", { children: "\u041D\u043E\u043C\u0435\u0440 \u0420\u0424" })] })] }));
};
export default inject('referenceStore')(observer(React.forwardRef(VehicleNumberInput)));
