import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useState, useMemo, useEffect, memo, } from 'react';
import { inject, observer } from 'mobx-react';
import { cloneDeep, isEqual } from 'lodash';
// Примеры, на базе чего делалось:
// https://codesandbox.io/p/sandbox/cjjhrt?file=%2Freact%2Findex.tsx%3A123%2C1-123%2C73 
// https://yandex.ru/dev/jsapi30/doc/ru/examples/cases/building-route
// https://yandex.com/dev/jsapi-v2-1/doc/en/v2-1/ref/reference/util.bounds#getCenterAndZoom
import { useFormContext } from 'react-hook-form';
import { 
// COORDINATES_MSK_CENTER,
MAX_ZOOM, MIN_ZOOM, ROUTE_KEYS, } from './consts';
import { fetchAddressByCoordinates } from './utils';
import { MinusIcon, PlusIcon, PositionArrowIcon } from '../core/icons';
import Marker from './Marker';
import './styles.css';
import { YMap, YMapComponentsProvider, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapListener, YMapFeature, YMapCollection,
// YMapControls,
// YMapGeolocationControl,
// YMapZoomControl,
// YMapHint,
// YMapDefaultMarker,
// YMapContainer,
// YMapControlButton,
// YMapHintContext,
// YMapMarker,
// YMapClusterer,
 } from 'ymap3-components';
import { useMediaQuery } from '@react-hooks-library/core';
import { referenceActions } from '../order/store/referenceStore';
function Map(props) {
    // export const Map: FC = () => { 
    // const hostname = useMemo(() => (window.location.hostname ?? '')?.trim(), []);
    var _a;
    const { referenceStore } = props;
    // const [mapRef, setMapRef] = useState(null);
    // const [center, setCenter] = useState<CoordinatesType>(COORDINATES_MSK_CENTER);
    // const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM);
    // const [tempZoom, setTempZoom] = useState<number>(DEFAULT_ZOOM);
    const [markers, setMarkers] = useState([undefined, undefined]);
    const [isSource, setIsSource] = useState(true);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const defaultLocation = {
        center: [37.610225, 55.751344],
        zoom: 11,
        duration: 0,
    };
    // основная локация:
    const [location, setLocation] = useState(defaultLocation);
    // вспомогательная локация (возможно, стоит вырезать для упрощения логики):
    const [viewLocation, setViewLocation] = useState(defaultLocation);
    const { setValue, getValues } = useFormContext(); // retrieve all hook methods
    const values = getValues();
    const { lat, lng, deliveryLatitude, deliveryLongitude } = values;
    const onUpdate = React.useCallback(({ location, mapInAction }) => {
        if (!mapInAction &&
            !isEqual(location, {
                center: location.center,
                zoom: location.zoom,
            })) {
            setViewLocation({
                center: location.center,
                zoom: location.zoom,
            });
        }
    }, []);
    useEffect(() => {
        if (values.lat && values.lng && values.deliveryLatitude && values.deliveryLongitude)
            referenceActions.getRoute({
                startCoordinates: [
                    values.lng,
                    values.lat,
                ],
                endCoordinates: [
                    values.deliveryLongitude,
                    values.deliveryLatitude,
                ],
                type: 'driving',
            });
    }, [values.lat, values.lng, values.deliveryLatitude, values.deliveryLongitude]);
    const updateStore = useCallback((point) => (coordinates, address, placeId) => {
        setValue(ROUTE_KEYS[point].lat, coordinates === null || coordinates === void 0 ? void 0 : coordinates.lat);
        setValue(ROUTE_KEYS[point].lng, coordinates === null || coordinates === void 0 ? void 0 : coordinates.lng);
        setValue(ROUTE_KEYS[point].address, address.replace('Россия, ', ''));
        setValue(ROUTE_KEYS[point].placeId, placeId);
    }, [setValue]);
    const onDragMovePointAHandler = useCallback((coordinates) => {
        if (!isEqual(values === null || values === void 0 ? void 0 : values.waypointA, coordinates)) {
            setValue('waypointA', coordinates);
            setValue('waypointB', null);
        }
        // Не добавляй ниже waypointA в зависимости - иначе курсор скачет
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValue]);
    const onDragMovePointBHandler = useCallback((coordinates) => {
        if (!isEqual(values === null || values === void 0 ? void 0 : values.waypointB, coordinates)) {
            setValue('waypointB', coordinates);
            setValue('waypointA', null);
        }
        ;
        // Не добавляй ниже waypointB в зависимости - иначе курсор скачет
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValue]);
    const onDragEndHandler = useCallback(() => {
        // пришлось через getValues отдельно достать значения из hookForm, иначе не работает
        const x = getValues();
        // setValue('count', (values?.count ?? 0) + 2);
        if (x.waypointA) {
            const newCoords = { lat: x.waypointA[1], lng: x.waypointA[0] };
            fetchAddressByCoordinates(newCoords, updateStore('source'));
            setValue('lat', x.waypointA[1]);
            setValue('lng', x.waypointA[0]);
        }
        if (x.waypointB) {
            const newCoords = { lat: x.waypointB[1], lng: x.waypointB[0] };
            fetchAddressByCoordinates(newCoords, updateStore('target'));
            setValue('deliveryLatitude', x.waypointB[1]);
            setValue('deliveryLongitude', x.waypointB[0]);
        }
    }, [getValues, setValue, updateStore]);
    /* A handler function that updates the route line
    and shifts the map to the new route boundaries, if they are available. */
    const routeHandler = useCallback((newRoute) => {
        var _a;
        // пришлось через getValues отдельно достать значения из hookForm, иначе не работает (с опозданием получаем значения и цикл ломается)
        const x = getValues();
        if (((_a = newRoute === null || newRoute === void 0 ? void 0 : newRoute.properties) === null || _a === void 0 ? void 0 : _a.bounds) && (x.isInputAddressMode)) {
            //  далее проведём спец.смещение долготы, чтоб визуально лучше смотрелось (только для десктопа, для мобилки надо ещё придумать,  как лучше показать)
            const fixedBounds = cloneDeep(newRoute.properties.bounds);
            if (!isMobile) {
                const x = (fixedBounds[1][0] - fixedBounds[0][0]) / 12;
                fixedBounds[0][0] -= 5 * x;
                fixedBounds[1][0] += x;
                const y = (fixedBounds[1][1] - fixedBounds[0][1]) / 12;
                fixedBounds[0][1] -= y;
                fixedBounds[1][1] += 2 * y;
            }
            ;
            setLocation(Object.assign(Object.assign({}, location), { bounds: fixedBounds, duration: 300 }));
            setValue('isInputAddressMode', false);
        }
    }, [getValues, isMobile, location, setValue]);
    useEffect(() => {
        routeHandler(referenceStore.route);
    }, [routeHandler, referenceStore.route]);
    const pins = useMemo(() => markers.reduce((acc, marker, index) => {
        if ((marker === null || marker === void 0 ? void 0 : marker.lat) !== undefined && (marker === null || marker === void 0 ? void 0 : marker.lng) !== undefined) {
            acc.push(_jsx(Marker, { lat: marker.lat, lng: marker.lng, 
                // office={ marker }
                iconIndex: index, isMobile: isMobile, selected: false, handleSelected: () => { }, draggable: true, onDragMove: index === 0 ? onDragMovePointAHandler : onDragMovePointBHandler, onDragEnd: onDragEndHandler }, `key_${marker.lat}-${marker.lng}`));
        }
        return acc;
    }, []), [markers, isMobile, onDragMovePointAHandler, onDragMovePointBHandler, onDragEndHandler]);
    const handleClick = useCallback(async (e, b) => {
        if (b === null || b === void 0 ? void 0 : b.coordinates) {
            const newCoords = { lat: b.coordinates[1], lng: b.coordinates[0] };
            if (isSource) {
                await fetchAddressByCoordinates(newCoords, updateStore('source'));
                setValue('lat', newCoords.lat);
                setValue('lng', newCoords.lng);
                setIsSource(false);
            }
            else {
                await fetchAddressByCoordinates(newCoords, updateStore('target'));
                setValue('deliveryLatitude', newCoords.lat);
                setValue('deliveryLongitude', newCoords.lng);
                setIsSource(true);
            }
        }
    }, [isSource, updateStore, setValue]);
    const handlePanToCurrentLocation = useCallback((e) => {
        e.preventDefault();
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const newLocation = Object.assign({}, viewLocation);
                newLocation.center[0] = position.coords.longitude;
                newLocation.center[1] = position.coords.latitude;
                setLocation(newLocation);
            });
        }
    }, [viewLocation]);
    const handlePlusZoom = useCallback((e) => {
        e.preventDefault();
        const newLocation = Object.assign(Object.assign({}, viewLocation), { zoom: viewLocation.zoom + 1 });
        if (viewLocation.zoom < MAX_ZOOM) {
            setLocation(newLocation);
        }
    }, [viewLocation]);
    const handleMinusZoom = useCallback((e) => {
        e.preventDefault();
        const newLocation = Object.assign(Object.assign({}, viewLocation), { zoom: viewLocation.zoom - 1 });
        if (viewLocation.zoom > MIN_ZOOM) {
            setLocation(newLocation);
        }
    }, [viewLocation]);
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (((_b = (_a = values.region) === null || _a === void 0 ? void 0 : _a.center) === null || _b === void 0 ? void 0 : _b.lat) && ((_d = (_c = values.region) === null || _c === void 0 ? void 0 : _c.center) === null || _d === void 0 ? void 0 : _d.lng)) {
            const newLocation = Object.assign({}, viewLocation);
            newLocation.center[0] = (_f = (_e = values.region) === null || _e === void 0 ? void 0 : _e.center) === null || _f === void 0 ? void 0 : _f.lng;
            newLocation.center[1] = (_h = (_g = values.region) === null || _g === void 0 ? void 0 : _g.center) === null || _h === void 0 ? void 0 : _h.lat;
            setLocation(newLocation);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.region]);
    useEffect(() => {
        // координата может быть ноль
        if (lat !== undefined && lng !== undefined) {
            setMarkers((prevState) => {
                return [{ lat, lng }, prevState[1]];
            });
            // handleCenter({ lat, lng });
        }
    }, [lat, lng]);
    useEffect(() => {
        if (deliveryLatitude !== undefined && deliveryLongitude !== undefined) {
            setMarkers((prevState) => {
                return [
                    prevState[0],
                    { lat: deliveryLatitude, lng: deliveryLongitude },
                ];
            });
            // handleCenter({ lat: deliveryLatitude, lng: deliveryLongitude });
        }
    }, [deliveryLatitude, deliveryLongitude]);
    // цвет из гугла: 722BF5
    const LINE_STYLE = {
        simplificationRate: 0,
        stroke: [
            { width: 7, color: '#83C753' },
            { width: 9, color: '#000000', opacity: 0.3 },
        ],
    };
    const hostname = ((_a = window.location.hostname) !== null && _a !== void 0 ? _a : '').trim();
    const isProd = hostname.includes('avtoapp.ru') ? true : false;
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: 'map_box', children: _jsx(YMapComponentsProvider, { apiKey: isProd ? '705499a3-a07a-4f4c-a5e1-967b2c073817' : process.env.YANDEX_API_KEY, lang: 'ru_RU', children: _jsxs(YMap, { 
                        // ref={ (ymap: YMaps.YMap) => setYmap(ymap) }
                        location: location, mode: 'vector', 
                        // TODO - Зум не влияет на кнопки справа на карте. Надо додумать, как их блокировать при Зуме мышкой, а не при клике
                        zoomRange: { min: MIN_ZOOM, max: MAX_ZOOM }, children: [_jsx(YMapDefaultSchemeLayer, {}), _jsx(YMapDefaultFeaturesLayer, {}), _jsx(YMapListener, { onUpdate: onUpdate, onClick: handleClick, 
                                // onFastClick = { (e) => { console.log('onFastClick ',e) } }
                                layer: 'any' }), pins, _jsx(YMapCollection, { children: (referenceStore === null || referenceStore === void 0 ? void 0 : referenceStore.route) && _jsx(YMapFeature, Object.assign({}, referenceStore.route, { style: LINE_STYLE })) })] }, 'map'
                    // ref={ (ymap: YMaps.YMap) => setYmap(ymap) }
                    ) }) }), _jsxs("div", { className: 'map_ui', children: [_jsx("button", { type: 'button', className: 'map_ui_button map_ui_plus', onClick: handlePlusZoom, disabled: viewLocation.zoom >= MAX_ZOOM, "aria-label": 'plus', children: _jsx(PlusIcon, {}) }), _jsx("button", { type: 'button', className: 'map_ui_button map_ui_minus', onClick: handleMinusZoom, disabled: viewLocation.zoom <= MIN_ZOOM, "aria-label": 'minus', children: _jsx(MinusIcon, {}) }), _jsx("button", { type: 'button', className: 'map_ui_button', onClick: handlePanToCurrentLocation, "aria-label": 'to_current', children: _jsx(PositionArrowIcon, {}) })] })] }));
}
export default memo(inject('referenceStore')(observer(Map)));
