import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// import { PinIcon } from '../../core/icons';
// import OfficeBalloon from '../OfficeBalloon';
import { PinParkingIcon, PinCarIcon } from '../../core/icons';
import { PinWrapper } from './styles';
import { YMapMarker, } from 'ymap3-components';
const Marker = ({ selected, handleSelected, isMobile, lat, lng, iconIndex, draggable, onDragEnd, onDragMove, }) => {
    return (_jsx(YMapMarker, { coordinates: [lng, lat], zIndex: selected ? 1 : 0, draggable: draggable, onDragEnd: onDragEnd, onDragMove: onDragMove, children: _jsxs(_Fragment, { children: [_jsx(PinWrapper, { selected: selected, onClick: handleSelected, children: iconIndex === 0 ? _jsx(PinCarIcon, {}) : _jsx(PinParkingIcon, {}) }), selected && !isMobile && (_jsx(_Fragment, {}))] }) }));
};
export default Marker;
