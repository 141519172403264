import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import PriceBox from './PriceBox';
import { bonusPercentByType, paramsPriceObj } from './PriceFunc';
import { calculatePriceActions, } from '../../order/store/calculatePriceStore';
const PriceComponent = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { calculatePriceStore, label, serviceId } = props;
    const { getValues } = useFormContext(); // retrieve all hook methods
    const values = getValues();
    const { error } = calculatePriceStore;
    const loadData = async () => {
        const isNoEqualTireFittingRequest = !isEqual(paramsPriceObj(values), calculatePriceStore.priceRequest);
        if (isNoEqualTireFittingRequest) {
            calculatePriceActions.calculatePrice(paramsPriceObj(values));
        }
    };
    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);
    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const localPrice = serviceId === 13
        ? (_b = (_a = calculatePriceStore === null || calculatePriceStore === void 0 ? void 0 : calculatePriceStore.priceResponse) === null || _a === void 0 ? void 0 : _a.evacuatorPrice) !== null && _b !== void 0 ? _b : 0
        : (_d = (_c = calculatePriceStore === null || calculatePriceStore === void 0 ? void 0 : calculatePriceStore.priceResponse) === null || _c === void 0 ? void 0 : _c.manipulatorPrice) !== null && _d !== void 0 ? _d : 0;
    const localBonus = serviceId === 13
        ? (_f = (_e = calculatePriceStore === null || calculatePriceStore === void 0 ? void 0 : calculatePriceStore.priceResponse) === null || _e === void 0 ? void 0 : _e.evacuatorBonusReceivingPercent) !== null && _f !== void 0 ? _f : 0
        : (_h = (_g = calculatePriceStore === null || calculatePriceStore === void 0 ? void 0 : calculatePriceStore.priceResponse) === null || _g === void 0 ? void 0 : _g.manipulatorBonusReceivingPercent) !== null && _h !== void 0 ? _h : 0;
    const isPriceFrom = () => {
        return true; // в любом случаем цену "от" показываем (Просьба Ассена)
        /* if (
          (values?.lat > 0)
            && (values?.lng > 0)
            && (values?.deliveryLatitude > 0)
            && (values?.deliveryLongitude > 0)
        ) {
          return false;
        }
        return true; */
    };
    const priceFromText = isPriceFrom() ? 'от ' : '';
    if (localPrice) {
        return (_jsx(PriceBox, { label: label, priceText: `${priceFromText}${(serviceId === 13
                ? localPrice.toString()
                : localPrice.toString())}`, bonus: Math.ceil((localPrice !== null && localPrice !== void 0 ? localPrice : 0) * bonusPercentByType(localBonus)), serviceId: serviceId, disabled: serviceId === 13
                && (((values === null || values === void 0 ? void 0 : values.wheelLockRefValueId) === 343)
                    || ((values === null || values === void 0 ? void 0 : values.wheelLockRefValueId) === 344)), hidePrice: !!(error === null || error === void 0 ? void 0 : error.errorCode) }));
    }
    return (null);
};
export default inject('calculatePriceStore')(observer(PriceComponent));
