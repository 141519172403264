// store для управление глобальными состояниями приложения
import { 
// action,
observable, runInAction, } from 'mobx';
export const stateAppStore = observable({
    stateOrder: 0,
    stateMobileMenu: 0,
    //   error: null,
});
export const stateAppActions = {
    setAppStateOrder: async (newState) => {
        runInAction(() => {
            stateAppStore.stateOrder = newState;
        });
    },
};
