import { action, observable, runInAction } from 'mobx';
import orderController from './controller';
export const calculatePriceStore = observable({
    priceRequest: null,
    priceResponse: null,
    error: null,
});
export const calculatePriceActions = {
    calculatePrice: async (request) => {
        // TODO - надо придумать, как получать значения по умолчанию
        const priceFrom = {
            manipulatorPrice: 2500,
            evacuatorPrice: 1500,
            manipulatorBonusReceivingPercent: 10,
            evacuatorBonusReceivingPercent: 10,
        };
        runInAction(() => {
            calculatePriceStore.priceRequest = request;
        });
        if ((request.startLat > 0)
            && (request.startLng > 0)
            && (request.endLng > 0)
            && (request.endLat > 0)) {
            const response = await orderController.calculatePrice(request);
            /* if (response.errorData) {
              calculatePriceActions.clearPrice();
            } */
            calculatePriceActions.handleError(response);
            if (!response.errorData) {
                calculatePriceActions.clearError();
                calculatePriceStore.priceResponse = response.data;
                return response.data;
            }
        }
        calculatePriceStore.priceResponse = priceFrom;
        return priceFrom;
    },
    clearPrice: async () => {
        calculatePriceStore.priceRequest = null;
        calculatePriceStore.priceResponse = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError: action((response) => {
        if (response.errorData) {
            const { errorData } = response;
            calculatePriceStore.error = errorData;
            // throw errorData;
        }
    }),
    clearError: action(() => {
        if (calculatePriceStore.error) {
            calculatePriceStore.error = null;
        }
    }),
};
