import { makeRequest } from '../../../lib/api';
ymaps3.ready.then(() => {
    ymaps3.import.registerCdn('https://cdn.jsdelivr.net/npm/{package}', '@yandex/ymaps3-default-ui-theme@0.0');
    // Copy your api key for routes from the developer's dashboard and paste it here
    ymaps3.getDefaultConfig().setApikeys({ router: process.env.YANDEX_ROUTE_API_KEY });
});
const OPEN_API_PATH = '/v1/open';
const AUTH_PATH = '/v3/client/auth';
// const SCHEDULER_PATH = '/v2/client/scheduler';
const CLIENT_PATH = '/v1/client';
// const YANDEX_PATH = '/v2/route';
// const PRICE_PATH = '/v1/open/prices/calculate/evacuation';
async function fetchRoute(startCoordinates, endCoordinates, type = 'driving') {
    let truck;
    if (type === 'truck') {
        // set truck with trailer by default
        truck = {
            weight: 40,
            maxWeight: 40,
            axleWeight: 10,
            payload: 20,
            height: 4,
            width: 2.5,
            length: 16,
            ecoClass: 4,
            hasTrailer: true,
        };
    }
    // Request a route from the Router API with the specified parameters.
    const routes = await ymaps3.route({
        points: [startCoordinates, endCoordinates], // Start and end points of the route LngLat[]
        type, // Type of the route
        bounds: true, // Flag indicating whether to include route boundaries in the response
        truck,
    });
    // Check if a route was found
    if (!routes[0])
        return;
    // Convert the received route to a RouteFeature object.
    const route = routes[0].toRoute();
    // Check if a route has coordinates
    if (route.geometry.coordinates.length == 0)
        return;
    return route;
}
class orderController {
    constructor() {
        Object.defineProperty(this, "getUniversalReference", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (refId) => {
                return makeRequest('get', `${OPEN_API_PATH}/ref_value/${refId}`);
            }
        });
        Object.defineProperty(this, "getRegions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return makeRequest('get', `${OPEN_API_PATH}/seo_pages`);
            }
        });
        Object.defineProperty(this, "getGeoPlaces", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                // return makeRequest('get', `${ OPEN_API_PATH }/gmaps/places?countryCode=${ request.countryCode ?? 'ru' }&input=${ request.input }`);
                return makeRequest('get', `${OPEN_API_PATH}/ymaps/places?input=${request.input}`);
            }
        });
        Object.defineProperty(this, "getGeoPlaceDirect", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                // return makeRequest('get', `${ OPEN_API_PATH }/gmaps/direct?placeId=${ request.placeId }`);
                return makeRequest('get', `${OPEN_API_PATH}/ymaps/direct?address=${request === null || request === void 0 ? void 0 : request.address}`);
            }
        });
        Object.defineProperty(this, "getGeoPlaceReverse", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                return makeRequest('get', `${OPEN_API_PATH}/ymaps/reverse?lat=${request.lat}&lng=${request.lng}`);
                // return makeRequest('get', `${ OPEN_API_PATH }/gmaps/reverse?lat=${ request.lat }&lng=${ request.lng }`);
            }
        });
        Object.defineProperty(this, "sendSms", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                return makeRequest('post', `${AUTH_PATH}/captcha`, request);
            }
        });
        Object.defineProperty(this, "confirmSms", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                return makeRequest('post', `${AUTH_PATH}/confirm/captcha`, request);
            }
        });
        Object.defineProperty(this, "getClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (token = null) => {
                return makeRequest('get', `${CLIENT_PATH}/profile`, {}, {}, 200, token);
            }
        });
        Object.defineProperty(this, "updateClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request, token = null) => {
                return makeRequest('put', `${CLIENT_PATH}/supplement`, request, {}, 200, token);
            }
        });
        Object.defineProperty(this, "createOrder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request, token = null) => {
                return makeRequest('post', `${CLIENT_PATH}/orders`, request, {}, 200, token);
            }
        });
        Object.defineProperty(this, "calculatePrice", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                return makeRequest('post', `${OPEN_API_PATH}/calculate/evacuation?maps=yandex`, request);
            }
        });
        Object.defineProperty(this, "getRoute", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => {
                return fetchRoute(request.startCoordinates, request.endCoordinates);
                // return makeYandexRequest('get', `${ YANDEX_PATH }?waypoints=${ request.startLat },${ request.startLng }|${ request.endLat },${ request.endLng }&apikey=84352203-c9a5-4b0d-a765-0e5ec9e87763&mode=driving`, request);
                // return makeYandexRequest('get', `${ YANDEX_PATH }?waypoints=55.59593923192594%2C37.716406427865685%7C55.80647951514909%2C37.399010743931825&apikey=84352203-c9a5-4b0d-a765-0e5ec9e87763&mode=driving`, request);
            }
        });
        // https://api.routing.yandex.net/v2/route?waypoints=55.59593923192594%2C37.716406427865685%7C55.80647951514909%2C37.399010743931825&apikey=79140fc9-4dd1-44f8-bc71-a2d91e56d631&mode=driving
    }
}
export default new orderController();
