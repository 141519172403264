import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
// import { useMediaQuery } from '@react-hooks-library/core';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { Row } from 'react-bootstrap';
import { referenceActions } from './store/referenceStore';
import { fillingOrderData } from './validation';
import { Header, Footer, SimpleInput, AddressSuggestionsInput, UrgentSelector, CheckBox, BoxSelector, BodyTypeSelector, PriceComponentRow, Point1Icon, Point2Icon, PathIcon, DateTimeSelector, } from '../core';
import { useScrollWithShadow } from '../core/hooks/useScrollWithShadow';
const AddressSelectPanel = (props) => {
    var _a;
    const { referenceStore, calculatePriceStore } = props;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const regions = (_a = referenceStore === null || referenceStore === void 0 ? void 0 : referenceStore.regions) !== null && _a !== void 0 ? _a : [];
    const { watch, setValue } = useFormContext(); // retrieve all hook methods
    const values = watch();
    // const [showRequired, setShowRequired] = useState<boolean>(false);
    const [ref21, setRef21] = useState([]);
    const [shouldValidate, setShouldValidate] = useState(false);
    const { error } = calculatePriceStore;
    const { scroller, positions } = useScrollWithShadow(ref21.length);
    // const isMobile = useMediaQuery('(max-width: 768px)');
    const location = useLocation();
    const optionsWheelLock = ref21.filter(v => v.id !== 55).map(v => {
        return v.id === 344
            ? {
                value: v.id,
                label: '4',
            }
            : {
                value: v.id,
                label: v.name,
            };
    });
    const loadData = async () => {
        await referenceActions.getRegions();
        const ref21Local = await referenceActions.getRef(21);
        setRef21(ref21Local);
    };
    useEffect(() => {
        loadData();
    }, []);
    useEffect(() => {
        if (location === null || location === void 0 ? void 0 : location.pathname) {
            setValue('region', regions.find(v => (`/${v === null || v === void 0 ? void 0 : v.path}` === ((location === null || location === void 0 ? void 0 : location.pathname) === '/' ? '/msk' : location === null || location === void 0 ? void 0 : location.pathname))));
        }
    }, [location === null || location === void 0 ? void 0 : location.pathname, regions, setValue]);
    /*   useEffect(() => {
      if (values?.fiasId) {
        setValue('region', regions.find(v => v.fiasId === values?.fiasId));
      }
    }, [regions, setValue, values?.fiasId]); */
    useEffect(() => {
        if (values.urgentRefValueId === 40) {
            setValue('dateString', `${format(new Date(), 'dd-MM-yyyy')} (сегодня)`);
        }
    }, [setValue, values.urgentRefValueId]);
    useEffect(() => {
        if (((values === null || values === void 0 ? void 0 : values.wheelLockRefValueId) === 343) || ((values === null || values === void 0 ? void 0 : values.wheelLockRefValueId) === 344)) {
            setValue('serviceId', 42);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values === null || values === void 0 ? void 0 : values.wheelLockRefValueId]);
    useEffect(() => {
        if (values === null || values === void 0 ? void 0 : values.isWheelLock) {
            setValue('wheelLockRefValueId', 56);
        }
        else {
            setValue('wheelLockRefValueId', 55);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values === null || values === void 0 ? void 0 : values.isWheelLock]);
    const validation = () => {
        setShouldValidate(true);
        return !!fillingOrderData.isValidSync(values);
    };
    // const params = new URLSearchParams(window.location.search);
    const cost = () => {
        var _a, _b, _c, _d;
        const x = (values === null || values === void 0 ? void 0 : values.serviceId) === 13
            ? (_b = (_a = calculatePriceStore === null || calculatePriceStore === void 0 ? void 0 : calculatePriceStore.priceResponse) === null || _a === void 0 ? void 0 : _a.evacuatorPrice) !== null && _b !== void 0 ? _b : 0
            : (_d = (_c = calculatePriceStore === null || calculatePriceStore === void 0 ? void 0 : calculatePriceStore.priceResponse) === null || _c === void 0 ? void 0 : _c.manipulatorPrice) !== null && _d !== void 0 ? _d : 0;
        const y = (error === null || error === void 0 ? void 0 : error.errorCode) === 'IMPOSSIBLE_ACTION' ? null : x;
        return y;
    };
    const date = new Date();
    useEffect(() => {
        if (!(values === null || values === void 0 ? void 0 : values.dateValue))
            setValue('dateValue', format(date, 'yyyy-MM-dd'));
        if (!(values === null || values === void 0 ? void 0 : values.hourValue))
            setValue('hourValue', '12');
        if (!(values === null || values === void 0 ? void 0 : values.minuteValue))
            setValue('minuteValue', '00');
        // setValue('dateString', `${ values.hourValue }:${ values.minuteValue }, ${ format(newDate, 'dd') } ${ format(newDate, 'MMMM') },${ format(newDate, 'EEEEEEE') }` ?? '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values === null || values === void 0 ? void 0 : values.urgentRefValueId]);
    return (_jsxs("div", { className: 'address_select', children: [_jsx(Header, { returnNumber: 0, header: '\u042D\u0432\u0430\u043A\u0443\u0430\u0446\u0438\u044F', 
                // noNavigate={ !isMobile || !!values.minimized }// включи, если нначнём работать с картой
                noNavigate: true /* !isMobile */, noReturn: true, gradient: true, onCloseClick: () => { setValue('minimized', !values.minimized); }, shadow: positions.top }), _jsxs("div", { className: 'padding_main_layer filling-client-data scroll_wrapper', ref: scroller, children: [_jsxs("div", { className: 'address_suggestions', children: [_jsxs("div", { className: 'icon_block', children: [_jsx(Point1Icon, {}), _jsxs("div", { className: 'path', children: [" ", _jsx(PathIcon, {}), " "] }), _jsxs("div", { className: 'point2', children: [" ", _jsx(Point2Icon, {}), " "] })] }), _jsxs("div", { className: 'suggestions_block', children: [_jsx(AddressSuggestionsInput, { label: '\u041E\u0442\u043A\u0443\u0434\u0430 \u0437\u0430\u0431\u0440\u0430\u0442\u044C', name: 'address', 
                                        // iconType='miniPin'
                                        autoFocus: true, namePlaceId: 'placeId', nameLat: 'lat', nameLng: 'lng', shouldValidate: shouldValidate, validationSchema: fillingOrderData, required: true }), _jsx(AddressSuggestionsInput, { label: '\u041A\u0443\u0434\u0430 \u043E\u0442\u0432\u0435\u0437\u0442\u0438', name: 'deliveryAddress', 
                                        // iconType='miniPin'
                                        namePlaceId: 'deliveryPlaceId', nameLat: 'deliveryLatitude', nameLng: 'deliveryLongitude', shouldValidate: shouldValidate, validationSchema: fillingOrderData, required: true })] })] }), _jsx(Row, { className: 'delimiter', children: _jsx("div", {}) }), _jsx(UrgentSelector, { label: '\u0414\u0430\u0442\u0430 \u0438 \u0432\u0440\u0435\u043C\u044F', required: true, name: 'urgentRefValueId', refId: 14, placeholder: '\u0412\u0440\u0435\u043C\u044F', iconType: 'clock' }), ((values === null || values === void 0 ? void 0 : values.urgentRefValueId) === 41)
                        && (_jsx(DateTimeSelector, { label: '\u042D\u0432\u0430\u043A\u0443\u0430\u0442\u043E\u0440 \u043F\u0440\u0438\u0435\u0434\u0435\u0442', required: true, name: 'urgentRefValueId', iconType: 'clock' })), _jsx(BodyTypeSelector, { label: '\u0422\u0438\u043F \u043A\u0443\u0437\u043E\u0432\u0430', required: true, name: 'bodyTypeRefValueId', options: [], placeholder: '\u0422\u0438\u043F \u043A\u0443\u0437\u043E\u0432\u0430', iconType: 'auto', shouldValidate: shouldValidate, validationSchema: fillingOrderData }), _jsx(Row, { className: 'delimiter', children: _jsx("div", {}) }), _jsx(CheckBox, { label: '\u041A\u043E\u043B\u0435\u0441\u0430 \u0437\u0430\u0431\u043B\u043E\u043A\u0438\u0440\u043E\u0432\u0430\u043D\u044B?', name: 'isWheelLock', helpTitle: '\u0415\u0441\u043B\u0438 \u043A\u043E\u043B\u0435\u0441\u0430 \u0432\u0430\u0448\u0435\u0433\u043E \u0430\u0432\u0442\u043E \u0437\u0430\u0431\u043B\u043E\u043A\u0438\u0440\u043E\u0432\u0430\u043D\u044B, \u043D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u0432\u044B\u0437\u0432\u0430\u0442\u044C \u043C\u0430\u043D\u0438\u043F\u0443\u043B\u044F\u0442\u043E\u0440 \u0434\u043B\u044F \u0431\u0435\u0437\u043E\u043F\u0430\u0441\u043D\u043E\u0433\u043E \u043F\u0435\u0440\u0435\u043C\u0435\u0449\u0435\u043D\u0438\u044F.' }), (values === null || values === void 0 ? void 0 : values.isWheelLock)
                        && (_jsx(BoxSelector
                        // label='Блокировка колёс'
                        , { 
                            // label='Блокировка колёс'
                            required: true, name: 'wheelLockRefValueId', options: optionsWheelLock })), _jsx(SimpleInput, { label: '\u041A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439 (\u043D\u0435\u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u043E)', name: 'comment', placeholder: '\u041E\u043F\u0438\u0448\u0438\u0442\u0435 \u043F\u0440\u0438\u0447\u0438\u043D\u0443 \u044D\u0432\u0430\u043A\u0443\u0430\u0446\u0438\u0438' }), _jsx(PriceComponentRow, {})] }), _jsx(Footer, { label: '\u041E\u0444\u043E\u0440\u043C\u0438\u0442\u044C \u0437\u0430\u043A\u0430\u0437', nextNumber: !values.minimized ? 1 : null, validation: validation, onClick: () => {
                    setValue('cost', cost());
                    setValue('minimized', false);
                }, variant: 'primary', shadow: positions.bottom })] }));
};
export default inject('referenceStore', 'calculatePriceStore')(observer(AddressSelectPanel));
